import { Injectable } from '@angular/core';
import { last, map, Observable, Subject, tap } from 'rxjs';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Gallery } from '@phothor/manage/gallery/state/gallery';
import { AbstractEntityService } from '@phothor/shared/abstract/abstract-entity.service';
import { GalleryItem } from '@phothor/manage/gallery/state/gallery-item';
import { AuthState } from '@phothor/auth/state/auth.state';
import { environment } from '../../../../environments/environment';
import { CoverUploadData } from '@phothor/manage/gallery/state/cover-upload.data';

@Injectable({
  providedIn: 'root',
})
export class GalleriesService extends AbstractEntityService<Gallery> {
  constructor(
    http: HttpClient,
    private authState: AuthState,
  ) {
    super('/api/galleries', http);
  }

  public shareModalSource: Subject<Gallery> = new Subject<Gallery>();
  public $shareModal: Observable<Gallery> = this.shareModalSource.asObservable();
  public shareModalGalleryUpdatedSource: Subject<Gallery> = new Subject<Gallery>();
  public $shareModalGalleryUpdated: Observable<Gallery> = this.shareModalGalleryUpdatedSource.asObservable();

  createGallery(): Observable<Gallery> {
    return this.http.post<Gallery>(this.apiUrl + this.basePath, {});
  }

  updateShare(
    id: any,
    form: {
      sharePassword: string | null;
      isShared: boolean;
      allowDownload: boolean;
      downloadQuality: string | null;
    },
  ): Observable<Gallery> {
    return this.http.put<Gallery>(this.apiUrl + this.basePath + '/' + id + '/share', form);
  }

  emailShareLink(galleryId: string, form: { email: string; message: string }): Observable<void> {
    return this.http.post<void>(this.apiUrl + this.basePath + '/' + galleryId + '/share/email', form);
  }

  update(form: any, id: any): Observable<Gallery> {
    return this.http.put<Gallery>(this.apiUrl + this.basePath + '/' + id, form);
  }

  getItems(galleryId: number) {
    return this.http.get<GalleryItem[]>(this.apiUrl + this.basePath + '/' + galleryId + '/items');
  }

  deleteItem(item: GalleryItem) {
    return this.http.delete(this.apiUrl + this.basePath + '/' + item.id);
  }

  watermarkGalleryItems(galleryId: string, watermarkId: string | null) {
    return this.http.post(this.apiUrl + this.basePath + '/' + galleryId + '/watermark/' + watermarkId, null);
  }

  removeWatermarkFromGallery(galleryId: string) {
    return this.http.delete(this.apiUrl + this.basePath + '/' + galleryId + '/watermark');
  }

  uploadCover(
    data: CoverUploadData,
    progressCallback: (progress: number) => void,
  ): Observable<{
    coverXLUrl: string;
    coverXSUrl: string;
  }> {
    const url = `${environment.uploadHost}/cover?${data.toQuery()}`;

    const headers = new HttpHeaders({
      'Content-Type': data.file.type,
    });

    const req = new HttpRequest('POST', url, data.file, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request<{ coverXLUrl: string; coverXSUrl: string }>(req).pipe(
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = event.total ? Math.round((100 * event.loaded) / event.total) : 0;
          progressCallback(progress);
        }
      }),
      last(),
      map((event) => {
        if (event.type === HttpEventType.Response) {
          if (event.status !== 200) {
            throw new Error('Upload failed: ' + event.status);
          } else {
            return event.body as { coverXLUrl: string; coverXSUrl: string };
          }
        }
        throw new Error('Upload failed');
      }),
    );
  }

  removeCover(galleryId: string) {
    return this.http.delete(this.apiUrl + this.basePath + '/' + galleryId + '/cover');
  }

  uploadBg(
    data: CoverUploadData,
    progressCallback: (progress: number) => void,
  ): Observable<{
    bgXLUrl: string;
    bgXSUrl: string;
  }> {
    const url = `${environment.uploadHost}/bg?${data.toQuery()}`;

    const headers = new HttpHeaders({
      'Content-Type': data.file.type,
    });

    const req = new HttpRequest('POST', url, data.file, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request<{ bgXLUrl: string; bgXSUrl: string }>(req).pipe(
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = event.total ? Math.round((100 * event.loaded) / event.total) : 0;
          progressCallback(progress);
        }
      }),
      last(),
      map((event) => {
        if (event.type === HttpEventType.Response) {
          if (event.status !== 200) {
            throw new Error('Upload failed: ' + event.status);
          } else {
            return event.body as { bgXLUrl: string; bgXSUrl: string };
          }
        }
        throw new Error('Upload failed');
      }),
    );
  }

  removeBg(galleryId: string) {
    return this.http.delete(this.apiUrl + this.basePath + '/' + galleryId + '/bg');
  }

  galleryStats(gallery: any): Observable<{ storageUsage: number; itemsCount: number }> {
    return this.http.get<{
      storageUsage: number;
      itemsCount: number;
    }>(this.apiUrl + this.basePath + '/' + gallery.id + '/stats');
  }
}
